import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { isUnevenColumn } from '../utils/numbers'

require('slick-carousel/slick/slick.css')
require('slick-carousel/slick/slick-theme.css')

const WrapperInfluencer = styled.section`
  vertical-align: top;
`

const Influencer = styled.div`
  background: ${props => props.theme.colors.white};
  position: relative;
  padding: 0.2em;
  width: 94%;
  transition: opacity 0.2s;
  margin: 0 0 1em 0;
  &:hover {
    // opacity: 0.8;
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    padding: 0 0 2em 0;
    margin: 0 auto 0 auto;
  }
  div {
    position: relative;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
      @media screen and (min-width: ${props => props.theme.responsive.small}px) {
        padding-bottom: ${props => (props.featured ? '40%' : '60%')};
      }
    }
  }
  .content-name {
    height: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px 10px;
  }
`

const Name = styled.h2`
  margin: auto;
  width: auto;
  font-family: Prompt;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: 0.0430833px;

  color: ${props => props.color ? props.color : props.theme.colors.white};
  
  text-align: right;
`

const BgImg = styled(Img)`
  min-height: 453px;
  margin-top: 0.5em;
`

const GridInfluencers = props => {
  return (
    <WrapperInfluencer>
      <Influencer 
        className={isUnevenColumn(props.index, props.columnNumber) ? 'image-padding' : ''}
      >
        <div>
          <BgImg fluid={props.image.fluid} />
          <div className="content-name">
            <Name color={props.footerColor || '#ffffff'}>{props.footer}</Name>
          </div>
        </div>
      </Influencer>
    </WrapperInfluencer> 
  )
}

export default GridInfluencers
