import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  width: 100%;
  padding-top: ${props => props.paddingTop || '1.3em'};
  padding-bottom: ${props => props.paddingBottom || '0'};
`

const TextTitle = styled.h1`
  padding: 0 25px;
  width: 100%;
  font-family: Raleway;
  font-style: normal;
  font-weight: ${props => props.big ? '200' : '300'};
  line-height: 125%;
  font-size: ${props => props.big ? '100px' : '24px'};
  text-align: ${props => props.big ? 'left' : 'center'};
  letter-spacing: 0.177083px;
  color: ${props => props.black ? '#000' : props.theme.colors.tertiary};
  word-break: break-word;

  @media screen and (max-width: 600px) {
    line-height: ${props => props.big ? '57px' : '25px'};
    font-size: ${props => props.big ? '50px' : '18px'};
    letter-spacing: 0.43px;

    &.subtitle {
      font-size: 24px; 
      line-height: 125%;
    }
  }
`

const Title = props => {
  return (
    <Wrapper 
      paddingTop={props.paddingTop}
      paddingBottom={props.paddingBottom}
    >
      <TextTitle
        className={props.className || ''}
        big={props.big || false}
        black={props.blackTitle}
      >{props.title}</TextTitle>
    </Wrapper>
  )
}

export default Title
