import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import styled from 'styled-components'

import Container from '../components/Container'
import SEO from '../components/SEO'
import DescriptionSection from '../components/DescriptionSection'
import StepSection from '../components/StepSection'
import FeaturesSection from '../components/FeaturesSection'
import GetQuoteSection from '../components/GetQuoteSection'
import HomeCaseStudies from '../components/HomeCaseStudies'
import GridInfluencers from '../components/GridInfluencers'
import Title from '../components/Title'
import GridInfluencerCard from '../components/GridInfluencerCard'
import HeroCarousel from '../components/HeroCarousel'

const GetQuoteContainer = styled.div`
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  margin-top: 6em;
  text-align: center;
  background: rgba(234, 230, 223, 0.4);
  padding: 3em 2em 5em;

  & .quote-content {
    margin: 0 auto;
    max-width: ${props => props.theme.sizes.maxWidth};
  }
`;

const Index = ({ data }) => {
  const influencers =
    data.allContentfulInfluencer.edges.length > 6
      ? data.allContentfulInfluencer.edges.slice(0, 6)
      : data.allContentfulInfluencer.edges
  const caseStudies =
    data.allContentfulCaseStudy.edges.length > 3
      ? data.allContentfulCaseStudy.edges.slice(0, 3)
      : data.allContentfulCaseStudy.edges
  const feacturesGallery =
    data.contentfulHomePage.feacturesGallery.length > 1
      ? data.contentfulHomePage.feacturesGallery
      : []

  const contentBrand = data.contentfulBrandPage
  const contentHome = data.contentfulHomePage

  const steps = contentHome.steps ? contentHome.steps.list 
    ? contentHome.steps.list : []
    : []  
  const slides = contentBrand.slides ? contentBrand.slides : [] 

  let layoutRef = React.createRef();
  
  const handleOnClickSignUp = () => {
    if (layoutRef && 
      layoutRef.current &&
      layoutRef.current.menuContainerRef) {
      
      const menuContainerRef = layoutRef.current.menuContainerRef;
      if (menuContainerRef.current && 
        menuContainerRef.current.menuRef && 
        menuContainerRef.current.menuRef.current &&
        menuContainerRef.current.menuRef.current.openInfluencerRegisterModal
      ) {
        menuContainerRef.current.menuRef.current.openInfluencerRegisterModal();
      }
    }
  }

  return (
    <Layout ref={layoutRef} whiteNav>
      <SEO />

      <HeroCarousel 
        slides={slides}
      />

      <Container padding={'5em 1.5em 2em'}>

        <DescriptionSection 
          message={contentBrand.description} 
          onClickButton={() => handleOnClickSignUp()}
          linkTitle="SIGN UP"
          noPadding
        />

        <FeaturesSection 
          feactures={feacturesGallery}
        />

        <GetQuoteContainer>
          <div className="quote-content">
            <Title className="subtitle" title={contentHome.stepsTitle} blackTitle/>
            <StepSection steps={steps} linkTitle="SCHEDULE CALL" tertiaryNumbers/>
          </div>
        </GetQuoteContainer>

        <GetQuoteSection 
          consultationTitle={contentHome.completeQuestionnaireTitle}
          consultationSubtitle={contentHome.completeQuestionnaireSubtitle}
          linkTo="/signup/bform"
          noMargin
          marginBottom="2em"
        />

        <HomeCaseStudies
          title={"CASE STUDIES"}
          caseStudies={caseStudies}
        />

        <GridInfluencers
          influencersTitle={contentHome.influencersTitle}
          columnNumber={3}
          marginTop="0"
        >
          {influencers.map(({ node: inf }, index) => (
            <div key={inf.id}>
              <GridInfluencerCard
                image={inf.heroImage}
                footer={inf.instagramHandler}
                columnNumber={3}
                index={index}
                footerColor={inf.footerTextColor}
              />
            </div>
          ))}
        </GridInfluencers>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulInfluencer(sort: { order: ASC, fields: [order] }) {
      edges {
        node {
          name
          instagramHandler
          footerTextColor
          headline
          id
          location
          slug
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
    allContentfulTestimonial(sort: { order: ASC, fields: [order] }) {
      edges {
        node {
          name
          id
          slug
          heroImage {
            title
            fluid(maxWidth: 51) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          brandLogo {
            title
            description
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
    allContentfulCaseStudy(sort: { order: ASC, fields: [createdAt] }, limit: 3) {
      edges {
        node {
          title
          slug
          id
          heroPreview {
            title
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          brandLogo {
            title
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          engagements
          impressions
        }
      }
    }
    contentfulBrandPage(version: { eq: "current" }) {
      slides {
        title
        image {
          title
          fluid(maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        slideContent {
          childMarkdownRemark {
            html
          }
        }
        contentPosition
        widthContentPct
        buttonText
        buttonAction
      }
      description
    }
    contentfulHomePage(version: { eq: "current" }) {
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      slides {
        title
        image {
          title
          fluid(maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        slideContent {
          childMarkdownRemark {
            html
          }
        }
        contentPosition
        widthContentPct
        buttonText
        buttonAction
      }
      description
      stepsTitle
      steps {
        list {
          title
          description
        }
      }
      completeQuestionnaireTitle
      completeQuestionnaireSubtitle {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      heroImageTitle
      heroImageSubtitle
      heroImageFooter {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      subtitle
      influencersTitle
      testimonialsTitle
      consultationTitle
      consultationSubtitle {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      feacturesGallery {
        id
        title
        description
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Index
