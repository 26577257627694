import React from 'react'
import styled from 'styled-components'
import Button from './Button';
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const Col = styled.div`
  &.col-medium {
    width: ${props => (props.sameCol ? "50%" : "60%")};
    padding: ${props => (props.sameCol ? "0 1em 0 2em" : props.inverted ? "0 3em 0 1em" : "0 2em")};
  }
  &.col-small {
    width: ${props => (props.sameCol ? "50%" : "40%")};
    padding: ${props => (props.sameCol ? "0 3em 0 1em" : props.inverted ? "0 1em 0 3em" : "0 2em")};
  }

  &.col-small {
    h3 {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 39px;
      letter-spacing: 0.0727273px;
      color: ${props => props.theme.colors.tertiary};
    }

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.0727273px;
      margin: 2em 0;
    }

    &.button-content {
      width: fit-content;

      a {
        text-decoration: none;
      }
    }
  }

  &.col-medium {
    h3 {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 50px;
      letter-spacing: 0.2em;
      color: ${props => (props.tertiary ? props.theme.colors.tertiary : props.theme.colors.black)};
    }

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 50px;
      /* or 192% */

      letter-spacing: 0.14em;
      margin: 1em 0;
    }

    &.button-content {
      width: fit-content;

      a {
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    &.col-medium {
      h3 {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.0727273px;
      }
      p {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    &.col-medium {
      width: 100%;
      padding: 0 0.5em;
    }
    &.col-small {
      margin: 3em 0 1.5em 0;
      padding: 0 0.5em;
      width: 100%;
    }

    &.col-small {
      h3 {
        font-size: 30px;
        line-height: 32px;
        letter-spacing: 0.0727273px;
      }
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &.col-medium {
      h3 {
        font-size: 25px;
        line-height: 27px;
        letter-spacing: 0.0727273px;
      }
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`

const Row = styled.section`
  display: flex;
  width: 100%;
  max-width: ${props => (props.maxWidth ? props.maxWidth : props.theme.sizes.maxWidthCentered)};
  align-items: center;
  margin: 1em 0 5em 0;

  &.row-right {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    &.row-right {
      flex-direction: column;
    }
  }

  @media screen and (min-width: 600px) {
    padding: 0 3em 0 0;
  }

  @media screen and (min-width: 700px) {
    padding: 0;
  }
`

const BgImg = styled(Img)`
  height: auto;
  width: 100%;
  margin-top: 40px;

  @media screen and (max-width: 600px) {
    margin-top: 30px;
  }
`

const ColTwo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 45px;
  justify-content: space-between;
  
  &.col:first-child {
    padding: 0 40px 0 0;
  }
  &.col:last-child {
    padding: 0 0 0 40px;
  }

  &.col {
    flex: 1;
  }

  & h3 {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.02em;

    color: ${props => (props.tertiary ? props.theme.colors.tertiary : props.theme.colors.black)};
  }

  & .feature-separator {
    width: 70%;
    margin: 20px 0;
    border-bottom: 1px solid  ${props => props.theme.colors.tertiary};
  }

  & p {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;

    letter-spacing: 0.05em;
  }

  @media screen and (max-width: 700px) {
    margin: 7em 0;
    padding: 0 45px;

    & p, & h3 {
      text-align: center;
    }

    & .feature-separator {
      margin: 20px auto;
    }

    &.col:first-child {
      margin: 0;
      padding: 0 45px;
    }
    &.col:last-child {
      margin: 0;
      padding: 0 45px;
    }
  }

  @media screen and (max-width: 600px) {
    margin: 5em 0;
    padding: 0 35px;

    &.col:first-child {
      margin: 0;
      padding: 0 35px;
    }
    &.col:last-child {
      margin: 0;
      padding: 0 35px;
    }
  }
`

const FeatureItemVertical = props => {
  return (
      <ColTwo className="col" tertiary={props.tertiary}>
        <div>
          <h3>{props.title || ''}</h3>
          <div className="feature-separator" />
          <p 
            dangerouslySetInnerHTML={{
            __html: props.description || ''
            }}
          />
        </div>
        <BgImg fluid={props.image.fluid}/>
        {/* <Col className={props.invertedSizes ? "col-small" : "col-medium"} inverted={props.invertedSizes} sameCol={props.sameCols}>
          <BgImg fluid={props.image.fluid}/>
        </Col>
        <Col 
          className={props.invertedSizes ? "col-medium" : "col-small"} 
          inverted={props.invertedSizes} 
          sameCol={props.sameCols} 
          tertiary={props.tertiary}
        >
          <h3>{props.title || ''}</h3>
          <p 
            dangerouslySetInnerHTML={{
            __html: props.description || ''
            }}
          />
          {props.includeButton &&
            <div className="button-content">
              <Link to='/signup/bform'>
                <Button 
                  padding="18.5px 44px" 
                  borderRadius="6.28832px"
                  className="rounded tertiary medium">
                  GET A QUOTE
                </Button>
              </Link>
            </div>
          }
        </Col> */}
      </ColTwo>
  )
}

export default FeatureItemVertical
