import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Slider from 'react-slick'
import Button from './Button'

const Wrapper = styled.section`
  position: relative;
  /* // margin-top: 90px; */
  min-height: 300px;
  margin-bottom: 1em;
  padding: 109px 0 0;
`
const BgImg = styled(Img)`

`

const Title = styled.h1`
  width: 100%;

  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  font-size: 48px;
  letter-spacing: 5px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 35px;

  @media screen and (max-width: 1440px) {
    font-size: 38px;
  }

  @media screen and (max-width: 1000px) {
    letter-spacing: 4px;
    font-size: 30px;
  }

  @media screen and (max-width: 820px) {
    line-height: 110%;
    letter-spacing: 3px;
    margin-bottom: 25px;
    font-size: 20px;
  }

  @media screen and (max-width: 670px) {
    margin-bottom: 15px;
    font-size: 15px;
  }

  @media screen and (max-width: 600px) {
    letter-spacing: 3px;
    margin-bottom: 10px;
    font-size: 12px;
  }
`

const Text = styled.div`
  margin-bottom: 35px;
  width: 100%;
  & p {
    /* display: flex; */
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    font-size: 17px;
    letter-spacing: 0.0456169px;
    color: ${props => props.theme.colors.secondary};
    margin-bottom: 1em;

    & span {
      font-size: 20px;
      color: ${props => props.theme.colors.tertiary};
      text-align: center;
      display: contents;
      display: inline-block;
      line-height: 0;
      vertical-align: middle;
    }
  }

  @media screen and (max-width: 1440px) {
    & p {
      font-size: 17px;
      & span {
        font-size: 25px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    line-height: 110%;
    & p {
      font-size: 13px;
      & span {
        font-size: 20px;
      }
    }
  }

  @media screen and (max-width: 820px) {
    margin-bottom: 10px;
    & p {
      font-size: 12px;
      margin-bottom: 0.5em;
      & span {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 670px) {
    margin-bottom: 5px;
    & p {
      font-size: 12px;
      & span {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    & p {
      line-height: 9px;
      font-size: 8px;
      & span {
        font-size: 10px;
      }
    }
  }
`

const SlideWrapper = styled.div`
  padding: 0 0 1em;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  min-height: 200px;
  max-height: 100vh;

  & .hero-content {
    display: flex;
    padding: 0 0 1em;
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    max-width: ${props => props.theme.sizes.maxNavWidth};
    left: 50%;
    transform: translate(-50%, 0%);

    & .hero-content-left{
      width: ${props => props.width || '50'}%;
      align-self: center;
      padding-left: 50px;

      & p, & h1  {
        text-align: left;
      }
    }

    & .hero-content-right{
      width: ${props => props.width || '50'}%;
      align-self: center;
      padding-right: 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & p, & h1 {
        text-align: right;
      }

      & .button-content {
        text-align: right;
      }
    }

    & .button-content {
      & a {
        text-decoration: none;
      }
    }
  }

  & .hero-content.hero-right {
    justify-content: flex-end;
  }

  @media screen and (max-width: 1440px) {
    & .hero-content {
      & .hero-content-left{
        padding-left: 50px;
      }
      & .hero-content-right{
        padding-right: 50px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    & .hero-content {
      & .hero-content-left{
        padding-left: 35px;
      }
      & .hero-content-right{
        padding-right: 35px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    & .hero-content {
      & .hero-content-left{
        padding-left: 25px;
        min-width: 50%;
      }
      & .hero-content-right{
        padding-right: 25px;
        min-width: 50%;
      }
    }
  }
  @media screen and (max-width: 400px) {
    & .hero-content {
      & .hero-content-left{
        padding-left: 15px;
      }
      & .hero-content-right{
        padding-right: 15px;
      }
    }
  }
`

const HeroCarousel = props => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    arrows: false,
    // autoplay: true,
    autoplaySpeed: 4000,
    dotsClass: 'slick-dots-new'
  }

  return (
    <Wrapper>
      <Slider  {...settings}>
        {props.slides && props.slides.map((slide, index) => 
          <SlideWrapper
            key={`hero-slide-${index}`}
            width={slide.widthContentPct}
          >
            <BgImg
              fluid={ slide.image.fluid } 
            />
            <div className={`hero-content ${slide.contentPosition ? 'hero-left' : 'hero-right'}`}>
              <div className={slide.contentPosition ? 'hero-content-left' : 'hero-content-right'}>
                <Title >
                  {slide.title}
                </Title>
                {slide.slideContent &&
                  <Text 
                    dangerouslySetInnerHTML={{
                      __html: slide.slideContent.childMarkdownRemark.html
                    }}
                  />
                }
                <div className="button-content">
                  <a 
                    href={slide.buttonAction || "https://go.oncehub.com/Embello"}
                    /* href={"digital-services"}  */
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <Button 
                      className="medium responsive"
                      padding={props.paddingButton || "13px 20px"} 
                      borderRadius="6.28832px">
                      {slide.buttonText || 'START NOW'}
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </SlideWrapper>
        )}
      </Slider>
    </Wrapper>
  )
}

export default HeroCarousel
