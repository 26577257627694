import React from 'react'
import styled from 'styled-components'
import Button from './Button'

const Subtitle = styled.h1`
  margin: auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  padding-top: ${props => props.noPadding ? '0' : '2.5em'};
  margin-bottom: 2.5em;

  object-fit: contain;
  font-family: Raleway;
  font-size: 48px;
  font-weight: ${props => props.fontWeight};
  font-style: normal;
  font-stretch: normal;
  line-height: 56px;
  letter-spacing: 0.239186px;
  text-align: center;

  color: ${props => props.theme.colors.black};

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 23px;

    padding-top: 0em;
    margin-bottom: 1.5em;
  }
  
`
const Wrapper = styled.section`
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
  margin-bottom: 9em;

  & .button-content {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  @media screen and (max-width: 600px) {
    padding-top: 0em;
    margin-bottom: 5em;
  }
`

const DescriptionSection = props => {
  return (
    <Wrapper marginTop={props.marginTop}>
      <Subtitle fontWeight={props.fontWeight || 'normal'} noPadding={props.noPadding}>{props.message}</Subtitle>
      {props.onClickButton &&
        <div className="button-content">
          <Button 
            className="medium"
            padding={props.paddingButton || "17px 60px"} 
            borderRadius="6.28832px"
            onClick={props.onClickButton}>
            {props.linkTitle || 'START NOW'}
          </Button>
        </div>
      }
    </Wrapper>
  )
}

export default DescriptionSection
