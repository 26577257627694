import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import theme from '../styles/theme'

require('slick-carousel/slick/slick.css')
require('slick-carousel/slick/slick-theme.css')

const Wrapper = styled.section`
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  margin-top: ${props => props.marginTop || '6em'};
  margin-bottom: 8em;
  // padding: 1em;

  .image-padding {
    bottom: -98px;
  }
  .slick-list {
    overflow: visible !important;
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    margin-bottom: 1em;
    .slick-list {
      overflow: hidden !important;
    }
    .image-padding {
      bottom: 0px;
    }
  }
`

const Wrapper2 = styled.section`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: 4em 2.5em 3em;
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    padding: 4em 0 3em;
  }
`

const Title = styled.h1`
  width: 100%;
  padding: 1.3em 0 0 0;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.177083px;
  color: ${props => props.theme.colors.tertiary};
  margin-bottom: 2em;

  @media screen and (max-width: 600px) {
    line-height: 25px;
    font-size: 18px;
    letter-spacing: 0.43px;
    margin-bottom: 1em;
    padding: 1.3em 2em 0 1em;

    &.subtitle {
      font-size: 24px; 
      line-height: 125%;
    }
  }
`

const GridInfluencers = props => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    rows: 2,
    slidesPerRow: props.columnNumber,
    arrows: false,
    dotsClass: 'slick-dots-new',
    responsive: [
      {
        breakpoint: theme.breakpoint.medium,
        settings: {
          slidesPerRow: 1,
          rows: 1,
          centerMode: true,
          centerPadding: '40px',
        },
      },
    ],
  }

  return (
    <Wrapper marginTop={props.marginTop}>
      <Wrapper2 paddingVertical={props.paddingVertical || '2.5em'}>
        { props.influencersTitle &&
          <Title className="subtitle">{props.influencersTitle}</Title>
        }
        <Slider {...settings}>
          {props.children}
        </Slider>
      </Wrapper2>
    </Wrapper>
  )
}

export default GridInfluencers
