import React from 'react'
import styled from 'styled-components'
import FeatureItemVertical from './FeatureItemVertical'

const Wrapper = styled.section`
  display: flex;
  margin-top: ${props => (props.marginTop ? props.marginTop : '9em')};
  justify-content: center;

  .button-content {
    width: fit-content;

    & a {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 700px) {
    margin-top: 6em;
  }
`

const RowTwo = styled.section`
  display: flex;
  width: 100%;
  max-width: ${props => (props.maxWidth ? props.maxWidth : props.theme.sizes.maxWidthCentered)};
  justify-content: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`

const FeaturesSection = props => {
  return (
    <Wrapper marginTop={props.marginTop}>
      <RowTwo>
        {props.feactures.map(image => ( 
          <FeatureItemVertical
            key={`featureHome${image.id}`}
            image={image}
            title={image.title}
            description={image.description}
            includeButton
            tertiary
          />
        ))}
      </RowTwo>
    </Wrapper>
  )
}

export default FeaturesSection
