import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  margin: 0 auto;
  max-width: ${props => props.maxWidth || props.theme.sizes.maxWidth};
  padding: ${props => props.padding || '3em 1.5em 2em'};
  // margin-top: 20px;

  @media screen and (max-width: 600px) {
    padding: 3em 2em 2em;
    padding: ${props => props.padding || '3em 1.5em 2em'};
  }
`

const Container = props => {
  return (
    <Wrapper 
      padding={props.padding} 
      verticalPadding={props.verticalPadding}
      maxWidth={props.maxWidth}>
      {props.children}
    </Wrapper>
  )
}

export default Container
