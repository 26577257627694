import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Slider from 'react-slick'
import theme from '../styles/theme'
import Img from 'gatsby-image'
import Button from './Button'
import { toStringFormat } from '../utils/numbers'

require('slick-carousel/slick/slick.css')
require('slick-carousel/slick/slick-theme.css')

const WrapperCaseStudy = styled.section`

`
const CaseStudy = styled.div`
  transition: background 0.2s;
  position: relative;
  width: 100%;
  margin: 0 auto 2vw auto;
  padding: 0 1.6em;

  .case-content {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
    justify-content: center;
    /* .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 100%;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        padding-bottom: ${props => (props.featured ? '100%' : '100%')};
      }
    } */
  }

  .case-properties {
    margin-top: 1.5em;
    display: flex;
    flex-direction: row;
  }

  .case-property {
    display: flex;
    flex-direction: row; 
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    padding:  0 0.5em;
    justify-content: center;
  }

  .case-property.property-left {
    justify-content: flex-end;
  }
  .case-property.property-right {
    justify-content: flex-start;
  }

  .case-property .property {
    display: flex;
    flex-direction: column;
  }

  .button-content {
    // width: 60%;
    margin: 2em auto;
    & a {
      text-decoration: none;
    }
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    padding: 0 0.5em;
    .button-content {
      // width: 80%;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    padding: 0 0.6em;
  }
`

const PreviewImage = styled(Img)`
  min-height: 240px;
  margin-top: 0.5em;
`

const BrandLogo = styled(Img)`
  height: 100%;
  // position: relative;
  // padding-bottom: 20% !important;
  img {
    object-fit: contain !important;
  }
`

const PreviewImageContent = styled.div`
  position: relative;

  .brand-logo-content {
    width: 100%;
    padding: 2% 8%;
    background: ${props => props.theme.colors.greenOverlay};
    position: absolute;
    z-index: 3;
    margin: auto 0;

    top: 0;
    bottom: 0;
    height: 20%;
  }
`

const Wrapper = styled.section`
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  margin-bottom: ${props => props.marginBottom};
`

const Wrapper2 = styled.section`
  margin: 0 auto;
  max-width: ${props => props.maxWidth}px;
  padding: 0 0;
  margin-top: 1.5em;

  & h3.case-property-value {
    font-family: Raleway;
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 35px;
    letter-spacing: 0.3px;
    color: ${props => props.theme.colors.darkGrey};
    text-align: center;
    margin-top: 0.5em;
  }

  & h3.case-property-title {
    font-family: Raleway;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-top: 0.8em;
    color: ${props => props.theme.colors.tertiary};
  }

  & .slick-list {
    padding: 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoint.extraLarge}px) {
    padding: 0 4em;
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    padding: 0 3em;
    & h3.case-property-value { 
      font-size: 16px;
      line-height: 20px;
    }
    & h3.case-property-title { 
      font-size: 10px;
      line-height: normal;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    padding: 0 0;
    margin-top: 4em;

    .slick-list {
      padding: 0px 40px !important;
    }

    & h3.case-property-value { 
      font-size: 30px;
      line-height: 36px;
    }
    & h3.case-property-title { 
      font-size: 14px;
      line-height: normal;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.small}px) {
    padding: 0 0;
    & h3.case-property-value { 
      font-size: 18px;
      line-height: 24px;
    }
    & h3.case-property-title { 
      font-size: 12px;
      line-height: normal;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  padding-top: 1.3em;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.177083px;
  color: ${props => props.theme.colors.tertiary};

  @media screen and (max-width: ${props => props.theme.breakpoint.small}px) {
    line-height: 25px;
    font-size: 18px;
    letter-spacing: 0.43px;
    padding-left: 1em;
    padding-right: 1em;

    &.subtitle {
      font-size: 24px; 
      line-height: 125%;
    }
  }
`

const HomeCaseStudies = props => {
  const slidesRowlength = props.caseStudies ? props.caseStudies.length : 3
  const maxWidthSlider = (slidesRowlength * 347) + ((slidesRowlength - 1) * 30 )

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    rows: 2,
    slidesPerRow: slidesRowlength,
    arrows: false,
    dotsClass: 'slick-dots-new',
    // centerMode: true,
    responsive: [
      {
        breakpoint: theme.breakpoint.smallMedium,
        settings: {
          slidesPerRow: 1,
          rows: 1,
          centerMode: true,
          centerPadding: '40px',
        },
      },
    ],
  }

  return (
    <Wrapper marginBottom={props.marginBottom ? props.marginBottom : '0px'}>
      <Title className="subtitle">{props.title || ''}</Title>
      <Wrapper2 maxWidth={maxWidthSlider}>
        <Slider {...settings}>
          {props.caseStudies.map(({ node: inf }, index) => (
            <WrapperCaseStudy key={`homeCaseStudy-${inf.id}`}>
              <CaseStudy>
                <div className="case-content">
                  <Link to={`/case/${inf.slug}/`}>
                    <PreviewImageContent>
                      <div className="brand-logo-content">
                        <BrandLogo fluid={inf.brandLogo.fluid}/>
                      </div>
                      <PreviewImage fluid={inf.heroPreview.fluid} />
                    </PreviewImageContent>
                  </Link>
                  <div className="case-properties">
                    <div className="case-property property-left">
                      <div className="property">
                        <h3 className="case-property-value">{toStringFormat(inf.engagements)}</h3>
                        <h3 className="case-property-title">ENGAGEMENTS</h3>
                      </div>
                    </div>
                    <div className="case-property property-right">
                      <div className="property">
                        <h3 className="case-property-value">{toStringFormat(inf.impressions)}</h3>
                        <h3 className="case-property-title">IMPRESSIONS</h3>
                      </div>
                    </div>
                  </div>
                  <div className="button-content">
                    <Link to={`/case/${inf.slug}/`}>
                      <Button padding="9px 20px">SEE RESULTS</Button>
                    </Link>
                  </div>
                </div>
              </CaseStudy>
            </WrapperCaseStudy>
          ))}
        </Slider>
      </Wrapper2>
    </Wrapper>
  )
}

export default HomeCaseStudies
