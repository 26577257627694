function toStringFormat(number) {
  if (number){
    const numberToFloat = parseFloat(number)
    return numberToFloat.toLocaleString('en-US')
  }
  return '0'
}

function getColumNumberFromArray(index, width) {
  if (index && width) {
    return index % width
  }
  return 0
}

function isUnevenNumber(number) {
  return !!((number%2))
}

function isUnevenColumn(index, width) {
  const column = getColumNumberFromArray(index, width)
  return isUnevenNumber(column)
}

module.exports = {
  toStringFormat,
  getColumNumberFromArray,
  isUnevenNumber,
  isUnevenColumn
}