import React from 'react'
import styled from 'styled-components'
import Button from './Button';
import { Link } from 'gatsby'
import { isInternalLink } from "is-internal-link";


const Col = styled.div`
  width: 22%;
  text-align: center;

  .step-number {
    display: flex;
    position: relative;
    margin-bottom: 4em;
    text-align: center;
    justify-content: center;
  }

  .step-number hr {
    display: flex;
    position: absolute;
    width: 100%;
    margin: 0;
    overflow: visible;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.theme.colors.green};
    top: 31px;
    left: 54%;
  }

  .step-number h1 {
    font-family: Raleway;
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    line-height: 52px;
    letter-spacing: 0.0456169px;
    color: ${props => props.tertiaryNumbers ? props.theme.colors.tertiary : props.theme.colors.green};
    text-align: center;
    padding: 0 0.5em;
    /* background: ${props => props.theme.colors.white}; */
    z-index: 4;
  }

  h5 {
    font-family: Raleway;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    line-height: 28px;
    letter-spacing: 0.0727273px;
    color: ${props => props.theme.colors.tertiary};
    margin-bottom: 2em;
  }

  p {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.0456169px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;

    .step-number {
      margin-bottom: 2.5em;
    }

    .step-number hr {
      display: none;
    }

    h5 {
      margin-bottom: 1.5em;
      font-weight: 300;
    }

    p {
      margin-bottom: 5em;
    }
  }

`

const Row = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4em;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    // margin-top: 5em;
    margin-bottom: 0em;
  }
`

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.marginTop ? props.marginTop : '6em')};
  align-items: center;

  .button-content {
    width: fit-content;

    & a {
      text-decoration: none;
    }
  }
`

const StepSection = props => {
  return (
    <Wrapper marginTop={props.marginTop}>
      <Row>
        {props.steps && props.steps.map((step, index) => (
          <Col key={`step-${index}`} tertiaryNumbers={props.tertiaryNumbers}>
            <div className="step-number">
              <h1>{index < 9 ? `${index+1}.` : `${index+1}.`}</h1>
              {/* ((index + 1) < props.steps.length) && 
                <hr className="step-line"/>
              */}
            </div>
            <h5>{step.title}</h5>
            <p>
              {step.description}
            </p>
          </Col>
        ))}
      </Row>
      <div className="button-content">
        {isInternalLink(props.linkTo) ?
          <Link to={props.linkTo}>
            <Button className="medium" padding="17px 44px">{props.linkTitle || 'LEARN MORE'}</Button>
          </Link>
          :
          <a href={props.linkTo || "https://calendly.com/embello/brand-consultation?back=1&month=2020-09"} target="_blank" rel="noopener noreferrer">
            <Button className="medium" padding="17px 44px">{props.linkTitle || 'LEARN MORE'}</Button>
          </a>
        }
      </div>
    </Wrapper>
  )
}

export default StepSection
