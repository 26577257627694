import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import { Link } from 'gatsby'

const Square = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`

const Wrapper = styled.section`
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;

  margin-top: ${props => props.noMargin ? '0' : '6em'};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : props.noMargin ? '0' : '8em'};
  text-align: center;

  background: ${props => props.background ||  props.theme.colors.pink};

  @media screen and (max-width: 600px) {
    margin-top: 1em;
    margin-bottom: 6em;
  }
`

const ConsultationTitle = styled.h2`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  font-family: Raleway;
  font-style: normal;
  font-weight: ${props => props.titleLg ? '500' : 'normal'}; 
  line-height: ${props => props.titleLg ? '48px' : '125%'}; 
  font-size: ${props => props.titleLg ? '38px' : '30px'}; 
  text-align: center;
  letter-spacing: ${props => props.titleLg ? '0.15em' : '0.470909px'}; 
  color: ${props => props.theme.colors.black};
  align-self: center;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 125%;
  }
`

const ConsultationSubtitle = styled.p`
  max-width: 526px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: ${props => props.theme.colors.black};
  margin: ${props => props.margin};
  align-self: center;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Wrapper2 = styled.section`
  margin: 0 auto;
  max-width: ${props => props.contentWidth ||  props.theme.sizes.maxWidth};
  padding: ${props => props.padding};

  .button-content {
    width: fit-content;

    & a {
      text-decoration: none;
    }
  }
  
`

const GetQuoteSection = props => {
  const paddingButton = props.paddingButton || '18.5px 44px';

  const renderLink = () => {
    if (props.externalLink) {
      return (
        <a 
          href={props.linkTo} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <Button className="medium" padding={paddingButton} borderRadius="6.28832px">
            {props.linkTitle || 'START NOW'}
          </Button>
        </a>
      )
    }

    return (
      <Link 
        to={props.linkTo}>
        <Button className="medium" padding={paddingButton} borderRadius="6.28832px">
          {props.linkTitle || 'START NOW'}
        </Button>
      </Link>
    )
  }

  return (
    <Wrapper noMargin={props.noMargin} background={props.background} marginBottom={props.marginBottom}>
      <Wrapper2 contentWidth={props.contentWidth} padding={props.padding || "5.3em 1.5em 3.5em"}>
        <Square>
          <ConsultationTitle titleLg={props.titleLg}>{props.consultationTitle}</ConsultationTitle>
          {props.consultationSubtitle ?
            <ConsultationSubtitle margin={props.contentMargin || "1.6em 0 1.9em 0"}>
              {props.consultationSubtitle.childMarkdownRemark.excerpt}
            </ConsultationSubtitle>
            :
            <ConsultationSubtitle margin={props.contentMargin || "1.6em 0 1.9em 0"} />
          }
          {props.children}
          <div className="button-content">
            {props.linkTo ?
              renderLink()
              :
              <Button 
                className="medium" 
                padding={paddingButton} 
                borderRadius="6.28832px"
                onClick={props.onClick}>
                {props.linkTitle || 'START NOW'}
              </Button>
            }
          </div>
        </Square>
      </Wrapper2>
    </Wrapper>
  )
}

export default GetQuoteSection
